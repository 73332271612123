import React, {useContext, useMemo, useState} from "react";
import styled from "styled-components";
import {Button, Grid} from "@material-ui/core";
import {useETHBalances} from "../../hook/wallet";
import {useActiveWeb3React} from "../../hook";
import {getBalanceFormat, getHexNumber} from "../../utils/formatBalance";
import {useGetConfigs, useSetTx} from "../../state/airdrop/hooks";
import {NEVER_RELOAD, useSingleCallResult} from "../../state/multicall/hooks";
import {useMultisenderContract, useTokenContract} from "../../hook/useContract";

import {
    ApprovalState,
    calculateGasMargin,
    useApproveCallback,
    useApproveCallbacks
} from "../../hook/useApproveCallback";

import {tryParseAmount} from "../../hook/tryParseAmount";
import {useToken} from "../../hook/Tokens";
import {useTransactionAdder} from "../../state/transactions/hooks";
import {TransactionResponse} from "@ethersproject/providers";
import {IndexContext} from "../../view";
import {MaxUint256} from "@ethersproject/constants";
import BigNumber from "bignumber.js";


export const Scpe2Widget: React.FC = () => {
    const {account,} = useActiveWeb3React();
    const ethBalances = useETHBalances(account ? [account] : []);
    const config = useGetConfigs();
    const {handleNext} = useContext(IndexContext);

    const tokenContract = useTokenContract(config.erc20adds);
    const multisenderContract = useMultisenderContract();
    const token: any = useToken(config.erc20adds);


    const addTransaction = useTransactionAdder()
    const tokenBalances = useSingleCallResult(tokenContract, 'balanceOf', [account ?? undefined], NEVER_RELOAD).result;
    const currentFeeBalances = useSingleCallResult(multisenderContract, 'currentFee', [], NEVER_RELOAD).result;
    const amount = useMemo(() => {
        return getHexNumber(tokenBalances);
    }, [tokenBalances]);
    const currentFee = useMemo(() => {
        return getHexNumber(currentFeeBalances);
    }, [currentFeeBalances]);

    const transfers = useMemo(() =>
        config.listBillingMsg.reduce((prev, cur, i) => {
            prev += parseFloat(cur.amount);
            return prev;
        }, 0), []);

    const amountToApprove = tryParseAmount((transfers).toString(), token);
    const [approval, approveCallback] = useApproveCallbacks(amountToApprove, multisenderContract?.address);

    const setTx = useSetTx();

    async function onStake() {
        if (multisenderContract && amountToApprove) {

            if (approval != ApprovalState.NOT_APPROVED) {
                const args = [
                    token.address,
                    config.listBillingMsg.map((ev) => ev.adds),
                    config.listBillingMsg.map((ev) => new BigNumber(10).pow(token.decimals).times(ev.amount).toFixed(),),
                    0
                ];


                let gasLimit = await multisenderContract.estimateGas.multisendToken(...args,{value: new BigNumber(1e18).times(currentFee).toFixed(),});


                multisenderContract
                    .multisendToken(
                        ...args,
                        {
                            gasLimit: calculateGasMargin(gasLimit),
                            value: new BigNumber(1e18).times(currentFee).toFixed(),
                        }
                    )
                    .then((response: TransactionResponse) => {
                        addTransaction(response, {
                            summary: `Deposit liquidity`
                        })
                        setTx(response.hash);
                        handleNext(2)

                    })
                    .catch((error: any) => {
                        console.log(error)
                    })
            } else {
                throw new Error('Attempting to stake without approval or a signature. Please contact support.')
            }
        }
    }


    return (<div>
        <Grid container justify={"space-between"}>
            <TopWidget title={'需转账代币数量'} value={transfers}/>
            <TopWidget title={'您的代币余额'} value={amount + (token?.name)}/>
            <TopWidget title={'您的 ETH 余额'} value={getBalanceFormat(account ? ethBalances[account] : '')}/>
        </Grid>
        <BottomWidget/>

        <Grid container justify={'center'}>
            <Grid item md={2}>
                {approval != 1 ?
                    <Button variant="contained" onClick={onStake} disabled={amount < transfers} color="primary">
                        确认
                    </Button> : <Button variant="contained" color="primary" onClick={approveCallback}>
                        Approval
                    </Button>}
            </Grid>
        </Grid>

    </div>);
};

export const BottomWidget: React.FC = () => {
    const {listBillingMsg} = useGetConfigs();

    return (
        <BottomWidgetStyle>
            <h3>收件人列表</h3>
            <h4>
                <span>地址</span>
                <span>总数量</span>
            </h4>
            <div>
                {
                    listBillingMsg.map((item) => <h5 key={item.adds}>
                        <span>{item.adds}</span>
                        <span>{item.amount}</span>
                    </h5>)
                }
            </div>
        </BottomWidgetStyle>
    );
}

const BottomWidgetStyle = styled(Grid)`
  text-align: center;
  margin: 40px 0;

  h3 {
    font-size: 28px;
    font-weight: 600;
  }

  h4 {
    display: flex;
    justify-content: space-between;
    color: #9cd8ff;
  }

  > div {
    h5 {
      display: flex;
      justify-content: space-between;
      color: black;
      border-bottom: 1px solid gold;
      padding: 10px;
    }
  }
`


const TopWidget: React.FC<{ title: string, value: any }> = ({value, title}) => {
    return (
        <TopWidgetStyle>
            <h2>{`${value}`}</h2>
            <h3>{`${title}`}</h3>
        </TopWidgetStyle>
    );
}

const TopWidgetStyle = styled(Grid)`
  text-align: center;

  h3 {
    font-size: 14px;
  }
`
