import React, {useMemo, useState} from "react";
import {useGetConfigs} from "../../state/airdrop/hooks";
import {Button, Grid} from "@material-ui/core";
import {BottomWidget} from "./scpe_2";


export const Scpe3Widget: React.FC = () => {
    const config = useGetConfigs();




    return (<div>
        <Grid container justify={"space-between"}>
          操作成功 tx: {config.tx}
        </Grid>
        <BottomWidget/>



    </div>);
};
