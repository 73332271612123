import React, {createContext, Dispatch, SetStateAction, useState} from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {Prepare} from '../components/multi_sender/scpe_1';
import styled from "styled-components";
import "codemirror/lib/codemirror.css";
import {Scpe2Widget} from "../components/multi_sender/scpe_2";
import {Scpe3Widget} from "../components/multi_sender/scpe_3";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

function getStepContent(stepIndex: number) {
    switch (stepIndex) {
        case 0:
            return <Prepare/>;
        case 1:
            return <Scpe2Widget />;
        case 2:
            return <Scpe3Widget />;
        default:
            return 'Unknown stepIndex';
    }
}

function getSteps() {
    return ['Prepare', 'Approve', 'Multisend'];
}


export const IndexPage: React.FC = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const transferList: [string, Dispatch<SetStateAction<string>>] = useState('');


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <IndexContext.Provider value={{handleNext, handleBack, transferList}}>
            <div className={classes.root}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <MContainer>
                    {getStepContent(activeStep)}
                </MContainer>
            </div>
        </IndexContext.Provider>

    );
}

export const IndexContext = createContext<{ handleBack: Function, handleNext: Function, transferList: [string, Dispatch<SetStateAction<string>>] }>({
    handleBack: () => {
    },
    handleNext(p0: number) {
    },
    transferList: ['', (ev) => null]
});


const MContainer = styled.div`
  max-width: 700px;
  margin: 50px auto;
`
