import MULTICALL_ABI from './abi.json'

// const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
//   [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
//   [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
//   [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
//   [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
//   [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e'
// }

export { MULTICALL_ABI }

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42
}

// 空投合约
export const MULTISENDER_NETWORKS:any = {
  [ChainId.MAINNET]: '0x07ab3e2a8c845ec0e3d15d576aef0777642d65b7',
  [ChainId.ROPSTEN]: '',
  [ChainId.KOVAN]: '0x8aa8cD13594ef51c12C81E7AB1f748Ea45E7acc9',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: ''
}

export const MULTICALL_NETWORKS:any = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e'
}
