import React, { useEffect, useState} from "react";
import {UnControlled} from 'react-codemirror2'
import {Button, FormControl, Grid, InputLabel, OutlinedInput} from "@material-ui/core";
import styled from "styled-components";
import {useVerAdds} from "../../hook/verification";
import {useActiveWeb3React} from "../../hook";
import {MULTISENDER_NETWORKS} from "../../constants/multicall";


export const Prepare: React.FC = () => {


    const [adds, setAdds] = useState('');
    const {chainId} = useActiveWeb3React();



    const  {setValue,value,verificationFn,symbol, decimals,myRef} = useVerAdds(adds);

    const handleFileReader = async (e: any) => {
        try {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e: any) => {
                setValue(e.target.result as string)
            };
            reader.readAsText(file);
        } catch (error) {
            console.log(error)
        }
    };




    useEffect(() => {

    }, [adds]);

    return (
        <div>
            <h3 style={{marginBottom:"10px"}}>Welcome to Token MultiSender <span style={{color:'red'}}>({`${symbol.result ?? '请输入合法 token 地址'}`})</span></h3>

            <form noValidate autoComplete="off">
                <Grid container spacing={4}>
                    <Grid item md={10} sm={8}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Address</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                labelWidth={60}
                                onChange={(ev) => setAdds(ev.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2}>
                        <FormControl variant="outlined" >
                            <InputLabel htmlFor="outlined-adornment-amount1" shrink={decimals != null}>
                                {
                                    symbol.loading ? 'waiting...' : 'Decimals'
                                }
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount1"
                                labelWidth={60}
                                value={decimals}
                                name={'decimals'}

                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>


            <MirrorStyle>
                <h2 className={'title'}>List of Addresses in CSV</h2>
                <UnControlled value={value}
                              ref={myRef}
                              options={{
                                  mode: 'xml',
                                  theme: 'material',
                                  lineNumbers: true,
                              }}
                              onChange={(editor, data, value) => {
                                  setValue(value);
                              }}/>
            </MirrorStyle>
            <UploadStyle variant="contained" color="primary">
                Upload CSV file
                <div className={'input'}>
                    <input type="file" onChange={handleFileReader}/>
                </div>
            </UploadStyle>
            <Grid container justify={'center'}>
                <Grid item md={2}>
                    {
                        MULTISENDER_NETWORKS[chainId] &&  <Button variant="contained" color="primary" onClick={() => verificationFn()}>
                            Send
                        </Button>
                    }
                    {
                        !MULTISENDER_NETWORKS[chainId] &&  <Button variant="contained" color="primary">
                            不支持当前网络
                        </Button>
                    }

                </Grid>
            </Grid>
        </div>
    );
}

const UploadStyle = styled(Button)`
  position: relative;

  .input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
`

const MirrorStyle = styled.div`
  margin: 10px 0;

  .title {
    color: #000;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .CodeMirror {
    //background: #111d39;
    border-radius: 4px;
    border: 1px solid #95d5ff;
    z-index: 1;
    transition: box-shadow .3s;
    line-height: 2;
    font-family: Menlo, Monaco, Courier New, monospace;

    .CodeMirror-gutter {
      width: 60px;
      //background: #1b3156;
    }

    .CodeMirror-gutters {
      border-right: 1px solid #95d5ff;

    }

    .CodeMirror-linenumber {
      color: #000;
      min-width: 2.9em;
      text-align: center;
    }

    .CodeMirror-line {
      padding: 0 20px;
    }

    .CodeMirror-lines {
      .CodeMirror-code {
        .tis_err {
          .CodeMirror-gutter-elt {
            color: red;
          }

          .CodeMirror-line {
            span {
              color: red;
            }
          }
        }
      }
    }
  }
`
